// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fs-9 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.nav-link {
  border-bottom: 2px solid transparent !important;
}

.nav-link:hover {
  border-bottom: 2px solid black !important;
  color: black !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.badge {
  position: absolute !important;
  top: 1px !important;
  right: 0px !important;
  background-color: #e63946 !important;
  color: white !important;
  border-radius: 50% !important;
  padding: 3px 3px !important;
  font-size: 10px !important;
  line-height: 1 !important;
  display: inline-block !important;
}

@media (max-width: 576px) {
  .fs-16 {
    font-size: 10px !important; /* Adjust this size as needed */
  }
  .fs-14 {
    font-size: 10px !important; /* Adjust this size as needed */
  }
}`, "",{"version":3,"sources":["webpack://./scss/style.scss"],"names":[],"mappings":"AAQA;EACE,0BAAA;AAPF;;AASA;EACE,0BAAA;AANF;;AAQA;EACE,0BAAA;AALF;;AAOA;EACE,0BAAA;AAJF;;AAMA;EACE,+CAAA;AAHF;;AAKA;EACE,yCAAA;EACA,uBAAA;AAFF;;AAIA;EACE,wBAAA;AADF;;AAGA;EACE,6BAAA;EACA,mBAAA;EACA,qBAAA;EACA,oCAAA;EACA,uBAAA;EACA,6BAAA;EACA,2BAAA;EACA,0BAAA;EACA,yBAAA;EACA,gCAAA;AAAF;;AAGA;EACE;IACE,0BAAA,EAAA,+BAAA;EAAF;EAEA;IACE,0BAAA,EAAA,+BAAA;EAAF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
